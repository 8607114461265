/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const Residencies = ({ data }) => {
  const { residencies } = data;
  return (
    <Layout
      bgcolor={residencies.backgroundColor}
      wrapperClass="residencies"
      accentColor={residencies.accentColor}
    >
      <HelmetDatoCms seo={residencies.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{residencies.title}</h1>
        </div>
        <div className="main-image col">
					<ImageFadeIn
						src={residencies.featuredImage.url}
						alt={residencies.featuredImage.alt}
						className="featured-title-image"
					/>  
          <p
            className="caption"
            dangerouslySetInnerHTML={{
              __html: residencies.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            dangerouslySetInnerHTML={{ __html: residencies.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={residencies.accentColor} />
          </div>
        </div>
        <div
          className="content main-text col"
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: residencies.description,
          }}
        />
      </div>
      <div className="residencies-previous container row">
        <div className="col-sm-2 col-xs-6 col">
          <h2 className="sidebar-text">{residencies.sideHeading}</h2>
        </div>
        <div className="tiles tiles-4 col-sm-12 col-xs-6 row col spacing-0">
          {residencies.tiles.map(({
            id,
            title,
            resident,
          }) => (
            <div className="tile artist-tile col" key={id}>
							<ImageFadeIn
								src={resident.url}
								alt={resident.alt}
								className="tile-image artist-featured-image"
							/>  
              <div className="tile-text-wrap artist-tile-text-wrapper">
                <div
                  className="content artist-dates"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={residencies.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

Residencies.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Residencies;

export const query = graphql`
  query ResidenciesQuery {
    residencies: datoCmsResidencie {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      accentColor {
        hex
      }
      backgroundColor
      description
      introduction
      title
      sideHeading
      featuredImage {
        alt
				url(imgixParams: { h: "900", ar: "5:3", fit: "crop", crop: "focalpoint" })
      }
      sideHeading
      tiles {
        id
        title
        resident {
          title
          alt
					url(imgixParams: { w: "400" h: "400", fit: "crop", crop: "focalpoint" })
        }
      }
    }
  }
`;
